.no-hover:hover {
  background-color: transparent !important;
    border: none !important;
  outline: none !important;
}

.dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: black !important; 
}

.dropdown-item {
  color: white !important;
  z-index: 10;
  display: block 
}

.dropdown-menu.show {
    background-color: black;
}

.ig {
    width: 45px;
    height: 45px;
}

.Image {
    border-radius: 20px;
}
.Price {
  text-decoration: none; /* Remove underline */
}

.Gap {
    padding-top: 0.85rem; /* Adjust as needed */
    padding-bottom: 0.85rem; /* Adjust as needed */
}

.Dis{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Div {
    display: flex;
    justify-content: center; /* Horizontally centers the items */
    align-items: center; /* Vertically centers the items */
}


.Service {
    height: auto;
    margin-bottom: 30px;
}

.Service .nav-tabs .nav-link.active {
    color: #fafaff;
    background-color: #b40051;
    border-color: var(--bs-nav-tabs-link-active-border-color);
}

.Service .nav-link {
    color: #000000;
}

.Service p{
    font-size: 1.2rem;

}

.Service-Tab ul,li{
    font-size: 1.2rem;
}

@media(max-width: 1000px) {
    .Service p{
        font-size: 1rem;
    }
 
}

@media(max-width: 500px) {
    .nav-item{
        width: 100vw;
        font-size: 1rem;
    }
 
}
